<template>
  <v-dialog v-model="dialogPurchaseOrderList" persistent max-width="1100px">
    <v-card class="pa-0" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <!-- Entry Info section -->
            <v-row>
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon
                  >{{ $vuetify.lang.t("$vuetify.purchaseList") }}</span
                >
              </v-col>
            </v-row>

            <v-row>
                <v-col></v-col>
            </v-row>

            <v-data-table
              v-model="selected"
              :headers="headers"
              :single-select="singleSelect"
              :items="datas"
              :items-per-page="10"
              item-class="primary--text"
              mobile-breakpoint="960"
              show-select
            ></v-data-table>


          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-spacer />
        <v-btn color="red darken-2" outlined @click="cancelForm">{{
          $vuetify.lang.t("$vuetify.cancel")
        }}</v-btn>
        <v-btn color="green darken-2" @click="handleSelectedData">{{
          $vuetify.lang.t("$vuetify.save")
        }}</v-btn>
        <v-spacer v-if="$vuetify.breakpoint.xsOnly" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props:{dialogPurchaseOrderList: Boolean},
    data(){
        return{
            valid:false,
            selected:[],
            headers:
            [
                {
                    text: this.$vuetify.lang.t("$vuetify.poNo"),
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.TotalAmount"),
                },
                {
                     text: this.$vuetify.lang.t("$vuetify.currency"),
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.AlreadyApplied"),
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.orderDate"),
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.ArrivalTime"),
                },
                {
                    text: this.$vuetify.lang.t("$vuetify.payment2"),
                },
            ],
            singleSelect: true,
            datas: [],
        }
    },
    methods:{
        cancelForm(){
            this.$emit("update:dialogPurchaseOrderList", false);
        },
        handleSelectedData(){
            
        }
    }
};
</script>